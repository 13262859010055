<template>
  <ListPage
    :article-list="articleList"
    :offset="offset"
    :total="total"
    :size="size">
    <template v-slot:summery>
      <CategoryWidget
        :name="$store.state.pageTitle"
        :description="$pubCtx.categoryDescription"
        sub-type="tag"/>
    </template>
  </ListPage>
</template>

<script>
import ListPage from './page-segments/ListPage';

export default {
  name: 'tag-list-page',
  dataResolver: 'tagListPage',
  components: {
    ListPage
  },
  data() {
    return {
      articleList: this.$store.state.articleList,
      offset: parseInt(this.$store.state.offset),
      total: parseInt(this.$store.state.total),
      size: parseInt(this.$store.state.size)
    };
  }
};
</script>
